export default class CommonAnime {
  get _MoveOpt() {
    const IF_MOBILE = innerWidth <= 768
    const IF_PC_LOW = innerHeight < 800
    const moveX = innerWidth / 4
    const defaultH = IF_MOBILE ? '26.93vw' : IF_PC_LOW ? '17.5vh' : 140
    const highH = IF_MOBILE ? '31.2vw' : IF_PC_LOW ? '21vh' : 170
    const currentY = IF_MOBILE ? '4.39vw' : 30
    return {
      river: [
        { up: { y: 0, height: defaultH, duration: 0 }, down: { y: 0, height: defaultH, duration: 0 } },
        { up: { y: 0, height: defaultH, duration: 0 }, down: { y: 0, height: defaultH, duration: 0 } },
        { up: { y: 0, height: defaultH, duration: 0.8 }, down: { y: 0, height: defaultH, duration: 0 } },
        { up: { y: 0, height: highH, duration: 0.8 }, down: { y: 0, height: highH, duration: 1.18, delay: 0.4 } },
        { up: { y: 0, height: highH, duration: 0.8 }, down: { y: 0, height: highH, duration: 0.8 } },
      ],
      current: [
        { up: { y: 0, opacity: 1, duration: 0 }, down: { y: 0, opacity: 1, duration: 0 } },
        { up: { y: 0, opacity: 1, duration: 0 }, down: { y: 0, opacity: 1, duration: 0 } },
        { up: { y: 0, opacity: 1, duration: 0.8 }, down: { y: 0, opacity: 1, duration: 0 } },
        { up: { y: currentY, opacity: 1, duration: 1.18, delay: 0.4 }, down: { y: currentY, opacity: 1, duration: 1.18, delay: 0.4 } },
        { up: { y: 140, opacity: 0, duration: 0.8 }, down: { y: 140, opacity: 0, duration: 0.8 } },
      ],
      groundL: [
        { up: { x: 0, y: 0, opacity: 1, duration: 0 }, down: { x: 0, y: 0, opacity: 1, duration: 0 } },
        { up: { x: 0, y: 0, opacity: 1, duration: 0 }, down: { x: 0, y: 0, opacity: 1, duration: 0 } },
        { up: { x: 0, y: 0, opacity: 1, duration: 0 }, down: { x: 0, y: 0, opacity: 1, duration: 0 } },
        { up: { x: 0, y: 0, opacity: 1, duration: 1.18, delay: 0.4 }, down: { x: 0, y: 0, opacity: 1, duration: 1.18 } },
        { up: { x: -moveX, y: 200, opacity: 0, duration: 0.8 }, down: { x: -moveX, y: 200, opacity: 0, duration: 1.4 } },
      ],
      groundR: [
        { up: { x: 0, y: 0, opacity: 1, duration: 0 }, down: { x: 0, y: 0, opacity: 1, duration: 0 } },
        { up: { x: 0, y: 0, opacity: 1, duration: 0 }, down: { x: 0, y: 0, opacity: 1, duration: 0 } },
        { up: { x: 0, y: 0, opacity: 1, duration: 0 }, down: { x: 0, y: 0, opacity: 1, duration: 0 } },
        { up: { x: 0, y: 0, opacity: 1, duration: 1.18, delay: 0.4 }, down: { x: 0, y: 0, opacity: 1, duration: 1.18 } },
        { up: { x: moveX, y: 200, opacity: 0, duration: 0.8 }, down: { x: moveX, y: 200, opacity: 0, duration: 1.4 } },
      ],
    }
  }

  get _ResetOpt() {
    const IF_MOBILE = innerWidth <= 768
    const IF_PC_LOW = innerHeight < 800
    const moveX = innerWidth / 4
    const defaultH = IF_MOBILE ? '26.93vw' : IF_PC_LOW ? '17.5vh' : 140
    const highH = IF_MOBILE ? '31.2vw' : IF_PC_LOW ? '21vh' : 170
    const currentY = IF_MOBILE ? '4.39vw' : 30
    return {
      river: [
        { y: 0, height: defaultH, overwrite: true },
        { y: 0, height: defaultH, overwrite: true },
        { y: 0, height: defaultH, overwrite: true },
        { y: 0, height: highH, overwrite: true },
        { y: 0, height: highH, overwrite: true },
      ],
      current: [
        { y: 0, opacity: 1, overwrite: true },
        { y: 0, opacity: 1, overwrite: true },
        { y: 0, opacity: 1, overwrite: true },
        { y: currentY, opacity: 1, overwrite: true },
        { y: 140, opacity: 0, overwrite: true },
      ],

      groundL: [
        { x: 0, y: 0, opacity: 1, overwrite: true },
        { x: 0, y: 0, opacity: 1, overwrite: true },
        { x: 0, y: 0, opacity: 1, overwrite: true },
        { x: 0, y: 0, opacity: 1, overwrite: true },
        { x: -moveX, y: 200, opacity: 0, overwrite: true },
      ],
      groundR: [
        { x: 0, y: 0, opacity: 1, overwrite: true },
        { x: 0, y: 0, opacity: 1, overwrite: true },
        { x: 0, y: 0, opacity: 1, overwrite: true },
        { x: 0, y: 0, opacity: 1, overwrite: true },
        { x: moveX, y: 200, opacity: 0, overwrite: true },
      ],
      ground: [
        { y: 0, overwrite: true },
        { y: 0, overwrite: true },
        { y: 0, overwrite: true },
        { y: 0, overwrite: true },
        { y: 0, overwrite: true },
      ],
      sun: [
        { opacity: 1, overwrite: true },
        { opacity: 1, overwrite: true },
        { opacity: 1, overwrite: true },
        { opacity: 1, overwrite: true },
        { opacity: 1, overwrite: true },
      ],
      duck: [{ opacity: 1 }, { opacity: 1 }, { opacity: 1 }, { opacity: 1 }, { opacity: 1 }],
    }
  }

  constructor($hero) {
    this.$elms = {}
    this.$global = $hero.querySelectorAll(`[data-elm]`)
    Array.from(this.$global, (elm) => (this.$elms[elm.dataset.elm] = elm))
  }

  // 初期位置の設定
  async set(index) {
    const { $elms, _ResetOpt } = this
    for (const name in _ResetOpt) {
      await gsap.set($elms[name], _ResetOpt[name][index])
      if (name === 'ground') {
        $elms[name].style = ''
      }
    }
  }

  // アニメーションの設定
  async move(section, direction) {
    const { $elms, _MoveOpt } = this
    return await Promise.all(
      Object.keys(_MoveOpt).map(async (name) => {
        return gsap.timeline({ defaults: { ease: 'power2.out' } }).to($elms[name], _MoveOpt[name][section][direction])
      })
    )
  }
}
