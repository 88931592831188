export default class KeyVisualAnime {
  get _NarrowHideOpt() {}

  get _WideHideOpt() {
    const moveX = innerWidth / 2
    const moveY = innerHeight / 5
    return {
      cloud2: { x: this._rOut('cloud2', 1.1), y: -30, scale: 1.1, opacity: 1, display: 'block', duration: 0.86 },
      cloud3: { x: this._lOut('cloud3', 1.1), y: -30, scale: 1.1, opacity: 1, display: 'block', duration: 0.86 },
      mount1: { x: moveX, y: 0, scale: 1.2, opacity: 1, display: 'block', duration: 1.4 },
      mount2: { x: -moveX, y: 0, scale: 1.2, opacity: 1, display: 'block', duration: 1.4 },
      mount3: { x: -moveX, y: 0, scale: 1.1, opacity: 1, display: 'block', duration: 1 },
      mount4: { x: moveX, y: 0, scale: 1.1, opacity: 1, display: 'block', duration: 1 },
      stone1: { x: -120, y: 120, scale: 1, opacity: 1, display: 'block', duration: 0.6 },
      stone2: { x: 120, y: 120, scale: 1, opacity: 1, display: 'block', duration: 0.6 },
      text: { x: 0, y: -moveY, scale: 1, opacity: 0, display: 'none', duration: 1 },
    }
  }

  get _ShowOpt() {
    return {
      cloud2: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.8 },
      cloud3: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.8 },
      mount1: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.8 },
      mount2: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.8 },
      mount3: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.8 },
      mount4: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.8 },
      stone1: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.8 },
      stone2: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.8 },
      text: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.8 },
    }
  }

  get _LoadOpt() {
    return {
      cloud2: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.2 },
      cloud3: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.2 },
      mount1: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.2 },
      mount2: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.2 },
      mount3: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.2 },
      mount4: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.2 },
      stone1: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.2 },
      stone2: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.2 },
      text: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.2 },
    }
  }

  constructor($hero) {
    this.$elms = {}
    this.$hero = $hero
    this.$global = document.querySelectorAll(`[data-elm]`)
    this.$kv = this.$hero.querySelectorAll(`[data-kv]`)
    this.$common = [...this.$kv, ...this.$global]
    Array.from(this.$common, (elm) => {
      const name = elm.dataset.kv || elm.dataset.elm
      if (!(name in this.$elms)) this.$elms[name] = []
      this.$elms[name].push(elm)
    })
  }

  // 初期位置の設定
  async setNarrowHide() {}

  async setWideHide() {
    const { $elms, _WideHideOpt } = this
    for (const name in _WideHideOpt) {
      gsap.set($elms[name], _WideHideOpt[name])
    }
  }

  async setShow() {
    const { $elms, _ShowOpt } = this
    for (const name in _ShowOpt) {
      gsap.set($elms[name], _ShowOpt[name])
    }
  }

  // アニメーションの設定
  async moveNarrowHide() {}

  async moveWideHide() {
    const { $elms, _WideHideOpt } = this
    const tl = gsap.timeline({ defaults: { ease: 'power2.out' } })
    for (const name in _WideHideOpt) {
      tl.to($elms[name], _WideHideOpt[name], '<')
    }
    return tl
  }

  async moveShow() {
    const { $elms, _ShowOpt } = this
    return gsap
      .timeline({ defaults: { ease: 'power2.out' } })
      .to($elms.cloud2, _ShowOpt.cloud2, '0.5')
      .to($elms.cloud3, _ShowOpt.cloud3, '<')
      .to($elms.mount1, _ShowOpt.mount1, '<')
      .to($elms.mount2, _ShowOpt.mount2, '<')
      .to($elms.mount3, _ShowOpt.mount1, '<')
      .to($elms.mount4, _ShowOpt.mount1, '<')
      .to($elms.stone1, _ShowOpt.stone1, '<')
      .to($elms.stone2, _ShowOpt.stone2, '<')
      .to($elms.text, _ShowOpt.text, '<')
  }

  async loadShow() {
    const { $elms, _LoadOpt } = this
    return gsap
      .timeline({ defaults: { ease: 'power2.out' } })
      .to($elms.cloud2, _LoadOpt.cloud2, '0.5')
      .to($elms.cloud3, _LoadOpt.cloud3, '<')
      .to($elms.mount1, _LoadOpt.mount1, '<')
      .to($elms.mount2, _LoadOpt.mount2, '<')
      .to($elms.mount3, _LoadOpt.mount1, '<')
      .to($elms.mount4, _LoadOpt.mount1, '<')
      .to($elms.stone1, _LoadOpt.stone1, '<')
      .to($elms.stone2, _LoadOpt.stone2, '<')
      .to($elms.text, _LoadOpt.text, '<')
  }

  _lOut(name, scale = 1) {
    const style = window.getComputedStyle(this.$elms[name][0])
    const offset = parseInt(style.getPropertyValue('margin-left'))
    const width = parseInt(style.getPropertyValue('width'))
    return -innerWidth / 2 - offset - width * scale
  }

  _rOut(name, scale = 1) {
    const style = window.getComputedStyle(this.$elms[name][0])
    const offset = parseInt(style.getPropertyValue('margin-left'))
    const width = parseInt(style.getPropertyValue('width'))
    return innerWidth / 2 - offset + (width * (scale - 1)) / 2
  }
}
