import Accordion from 'ui/accordion'

export default class Recruit {
  constructor() {
    this.$hero = document.querySelector(`[data-elm='hero']`)
    this.parallax = this.parallax.bind(this)
  }

  init(data) {
    const { $hero } = this
    if (!window.topMove) {
      $hero.style.display = 'none'
    }

    this.$accordion = [...document.querySelectorAll(`[data-accordion='wrap']`)]
    this.$accordion.forEach((elm) => new Accordion(elm))

    window.addEventListener('scroll', this.parallax)
    console.log('Recruit index')
  }

  break() {
    window.removeEventListener('scroll', this.parallax)
  }

  parallax() {
    console.log('Recruit_parallax')
    const $parallax = document.querySelector(`[data-parallax='wrap']`)
    const rect = $parallax.getBoundingClientRect()
    const y = rect.bottom - innerHeight / 2 - rect.height / 2
    gsap.to($parallax.querySelector(`[data-parallax='inner']`), {
      y: y * 0.1,
      duration: 0.2,
    })
  }
}
