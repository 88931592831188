export default class Cursor {
  constructor() {
    this.IF_MOBILE = innerWidth <= 768
    this._areaEnter = this._areaEnter.bind(this)
    this._areaLeave = this._areaLeave.bind(this)
  }

  init() {
    this.$slideArea = [...document.querySelectorAll(`.swiper-wrapper`)]
    if (!this.$slideArea || this.IF_MOBILE) return
    this.$cursor = document.querySelector(`[data-cursor='slide']`)
    this.$cursorInner = document.querySelector(`[data-cursor-inner='slide']`)
    this.$slideArea.forEach(($elm) => {
      if ($elm.children.length > 1) {
        $elm.addEventListener('mouseenter', this._areaEnter)
        $elm.addEventListener('mouseleave', this._areaLeave)
      } else {
        $elm.classList.add('single')
      }
    })
  }

  break() {
    if (!this.$slideArea || this.IF_MOBILE) return
    this.$slideArea.forEach(($elm) => {
      $elm.removeEventListener('mouseenter', this._areaEnter)
      $elm.removeEventListener('mouseleave', this._areaLeave)
    })
    this._areaLeave()
  }

  _areaEnter() {
    this.$cursor.style.display = 'block'
    this.$cursor.classList.add('is-show')
    gsap.fromTo(
      this.$cursorInner,
      { scale: 0.6, opacity: 0 },
      {
        scale: 1,
        opacity: 1,
        ease: 'power2.out',
        overwrite: true,
        duration: 0.6,
        onComplete: () => this.$cursor.classList.remove('is-show'),
      }
    )
  }

  _areaLeave() {
    gsap.to(this.$cursorInner, {
      scale: 0.6,
      opacity: 0,
      ease: 'power2.out',
      duration: 0.6,
      onComplete: () => {
        this.$cursor.style.display = 'none'
      },
    })
  }
}
