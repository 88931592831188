export default class Entry {
  constructor() {
    this.data = null
    this.dataList = {}
    this.listLength = 12
    this._detailPageNextPrev = this._detailPageNextPrev.bind(this)
  }

  async init(data) {
    this.param = data.next.url.query
    this.paramCat = this.param.cat
    this.paramPage = Number(this.param.page)
    this.category = this.paramCat || 'lp'
    this.listIndex = this.paramPage || 0

    this.$elms = {}
    this.$entry = [...document.querySelectorAll(`[data-entry]`)]
    this.$category = [...document.querySelectorAll(`[data-category]`)]

    this.$entry.forEach((elm) => (this.$elms[elm.dataset.entry] = elm))

    const IF_DETAIL = location.pathname.indexOf('detail') !== -1

    if (!this.data) {
      await fetch(`/assets/data/works.json`)
        .then((response) => response.json())
        .then(this._createArray.bind(this))
    }

    // console.log(this.data)
    if (!IF_DETAIL) this._indexPageInit()
    if (IF_DETAIL) this._detailPageInit()

    if (this.paramCat) {
      setTimeout(() => {
        gsap.to(window, {
          duration: 0.6,
          scrollTo: {
            y: document.querySelector(`.choices`),
            offsetY: 50,
            autoKill: false,
          },
          ease: 'power2.out',
        })
      }, 200)
    }
  }

  async transition(opacity = 0) {
    const { $elms } = this
    const _opacity = opacity ? 0 : 1
    await gsap.fromTo($elms.page, { opacity: _opacity, duration: 1.4 }, { opacity: opacity, duration: 0.4 })
  }

  _createArray(data) {
    const { listLength } = this
    this.data = data
    for (const name in data) {
      this.dataList[name] = this._splitArray(data[name], listLength)
    }
  }

  _indexPageInit() {
    const { $elms } = this
    if (!$elms.page) return false

    this.$category.forEach(($elm) => {
      const method = this.category === $elm.dataset.category ? 'add' : 'remove'
      $elm.classList[method]('is-current')
    })

    this._indexPageCreate()
    this.transition(1)
  }

  async _indexPageCreate() {
    const { $elms, dataList, category, listIndex, $screen } = this
    const template = doT.template($elms.pageTemplate.innerHTML)
    const array = dataList[category]
    const arrayLength = array.length
    if (!array) return false

    const createArray = {
      category: category,
      entries: array[listIndex],
    }
    $elms.page.innerHTML = template(createArray)
    $elms.pageIndex.innerHTML = listIndex + 1
    $elms.pageLength.innerHTML = arrayLength
    this._indexPageNation(arrayLength)
    return true
  }

  _indexPageNation(arrayLength) {
    const { $elms, category, listIndex } = this
    if (arrayLength < 2) {
      $elms.pageNation.style.visibility = 'hidden'
    } else {
      $elms.pageNation.style.visibility = ''
    }

    if (arrayLength - 1 <= listIndex) {
      $elms.pageNext.style.visibility = 'hidden'
    } else {
      $elms.pageNext.style.visibility = ''
      $elms.pageNext.href = `./?cat=${category}&page=${listIndex + 1}`
    }

    if (listIndex <= 0) {
      $elms.pagePrev.style.visibility = 'hidden'
    } else {
      $elms.pagePrev.style.visibility = ''
      $elms.pagePrev.href = `./?cat=${category}&page=${listIndex - 1}`
    }
  }

  _detailPageInit() {
    const { $elms } = this
    if (!$elms.detail) return false
    $elms.pagePrev.addEventListener('click', this._detailPageNextPrev)
    $elms.pageNext.addEventListener('click', this._detailPageNextPrev)
    this._detailCreate()
  }

  _detailPageNextPrev(event) {
    const type = event.currentTarget.dataset.entry === 'pagePrev' ? -1 : 1
    const { id, cat } = this._parameter()
    const array = this.data[cat]
    const index = array.findIndex((elm) => elm.id === Number(id))
    let obj = array[index + type]
    if (obj) {
      barba.go(`${location.pathname}?id=${obj.id}&cat=${cat}`)
    }
  }

  _detailCreate() {
    const { $elms, data } = this
    const { id, cat } = this._parameter()
    const template = doT.template($elms.detailTemplate.innerHTML)
    const array = data[cat]
    const index = array.findIndex((elm) => elm.id === Number(id))
    let obj = array[index]
    obj['cat'] = cat
    $elms.detail.innerHTML = template(obj)
    this._detailPageNation(array, index)
    this._detailPageTDK(obj)
  }

  _detailPageNation(array, index) {
    const { $elms } = this
    $elms.pagePrev.style.visibility = array[index - 1] ? '' : 'hidden'
    $elms.pageNext.style.visibility = array[index + 1] ? '' : 'hidden'
  }

  _detailPageTDK(obj) {
    const $head = document.head
    $head.querySelector('title').textContent = $head.querySelector('title').textContent + '｜' + obj.title
  }

  _splitArray(array, num) {
    const length = Math.ceil(array.length / num)
    return new Array(length).fill().map((_, i) => {
      return array.slice(i * num, (i + 1) * num)
    })
  }

  _scrollTo() {
    gsap.to(window, {
      duration: 0.6,
      scrollTo: {
        y: document.querySelector('.section-1'),
        offsetY: 50,
        autoKill: false,
      },
      ease: 'power2.out',
    })
  }

  _parameter() {
    let v = []
    const url = location.href.replace(/#.*$/, '')
    const hashes = url.slice(window.location.href.indexOf('?') + 1).split('&')
    for (let hash of hashes) {
      const h = hash.split('=')
      v.push(h[0])
      v[h[0]] = h[1]
    }
    return v
  }
}
