export default class About {
  constructor() {
    this.$hero = document.querySelector(`[data-elm='hero']`)
    this.parallax = this.parallax.bind(this)
  }

  init(data) {
    const { $hero } = this
    if (!window.topMove) {
      $hero.style.display = 'none'
    }

    this.$map = document.querySelector(`[data-map]`)
    window.addEventListener('scroll', this.parallax)

    try {
      setTimeout(() => {
        this._map()
      }, 500)
    } catch (e) {
      console.error(e)
    }
    console.log('About index')
  }

  break() {
    window.removeEventListener('scroll', this.parallax)
  }

  _map() {
    const { $map } = this
    console.log(google)
    const coordinate = {
      lat: 35.6862911,
      lng: 139.7035741,
    }
    $map.map = new google.maps.Map($map, {
      center: coordinate,
      zoom: 16,
      draggable: true,
      disableDefaultUI: false,
    })
    $map.marker = new google.maps.Marker({
      map: $map.map,
      icon: {
        url: '/assets/img/pin.svg',
        scaledSize: new google.maps.Size(50, 50),
      },
      place: {
        location: coordinate,
        query: '株式会社シン・クルーズ',
      },
    })
  }

  parallax() {
    const $parallax = document.querySelector(`[data-parallax='wrap']`)
    const rect = $parallax.getBoundingClientRect()
    const y = rect.bottom - innerHeight / 2 - rect.height / 2
    gsap.to($parallax.querySelector(`[data-parallax='inner']`), {
      y: y * 0.1,
      duration: 0.2,
    })
  }
}
