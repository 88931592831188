export default class Cursor {
  constructor($hero) {
    this.linkEnter = this.linkEnter.bind(this)
    this.linkLeave = this.linkLeave.bind(this)

    this.keyAreaEnter = this.keyAreaEnter.bind(this)
    this.keyAreaLeave = this.keyAreaLeave.bind(this)

    this.$kv = document.querySelector(`[data-elm='hero']`)
    this.$transition = document.querySelector(`[data-cursor='transition']`)
    this.$transitionInner = document.querySelector(`[data-cursor-inner='transition']`)
    this.$scroll = document.querySelector(`[data-cursor='scroll']`)
    this.$scrollInner = document.querySelector(`[data-cursor-inner='scroll']`)
    this.$linkArea = [...$hero.querySelectorAll(`[data-chase]`)]

    window.addEventListener('loadingEnd', () => {
      this.$kv.addEventListener('mouseenter', this.keyAreaEnter)
      this.$kv.addEventListener('mouseleave', this.keyAreaLeave)
    })

    this.bind()

    barba.hooks.after(({ next }) => {
      if (next.namespace !== 'top') {
        this.keyAreaLeave()
      }
    })
  }

  bind() {
    this.$linkArea.forEach(($elm) => {
      $elm.addEventListener('mouseenter', this.linkEnter)
      $elm.addEventListener('mouseleave', this.linkLeave)
    })
  }

  unbind() {
    this.$linkArea.forEach(($elm) => {
      $elm.removeEventListener('mouseenter', this.linkEnter)
      $elm.removeEventListener('mouseleave', this.linkLeave)
    })
    this.linkLeave()
  }

  cursorSelect(index) {
    // console.log('index', index)
    if (index === 0) {
      this.NOT_TOP = false
      this.keyAreaEnter()
    } else {
      this.NOT_TOP = true
      this.keyAreaLeave()
    }
  }

  keyAreaEnter() {
    if (innerWidth <= 768) return
    if (this.NOT_TOP) return
    this.$scroll.style.display = 'block'
    this.$scroll.classList.add('is-show')
    gsap.fromTo(
      this.$scrollInner,
      { scale: 0.6, opacity: 0 },
      {
        scale: 1,
        opacity: 1,
        ease: 'power2.out',
        overwrite: true,
        duration: 0.6,
        onComplete: () => this.$scroll.classList.remove('is-show'),
      }
    )
  }

  keyAreaLeave() {
    if (innerWidth <= 768) return
    gsap.to(this.$scrollInner, {
      scale: 0.6,
      opacity: 0,
      ease: 'power2.out',
      duration: 0.6,
      onComplete: () => {
        this.$scroll.style.display = 'none'
      },
    })
  }

  linkEnter(event) {
    window.title.pause()
    gsap.to(event.currentTarget, {
      scale: 1.1,
      ease: 'power4.out',
      duration: 0.6,
    })

    this.$transition.style.display = 'block'
    this.$transition.classList.add('is-show')
    gsap.fromTo(
      this.$transitionInner,
      { scale: 0.6, opacity: 0 },
      {
        scale: 1,
        opacity: 1,
        ease: 'power2.out',
        overwrite: true,
        duration: 0.6,
        onComplete: () => this.$transition.classList.remove('is-show'),
      }
    )
  }

  linkLeave(event) {
    gsap.to(event.currentTarget, {
      scale: 1,
      ease: 'power4.out',
      duration: 0.6,
    })

    gsap.to(this.$transitionInner, {
      scale: 0.6,
      opacity: 0,
      ease: 'power2.out',
      duration: 0.6,
      onComplete: () => {
        this.$transition.style.display = 'none'
      },
    })
    window.title.play()
  }
}
