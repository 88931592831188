export default class ContactAnime {
  get _NarrowHideOpt() {
    const IF_M = innerWidth <= 768
    return {
      cloud1: { x: IF_M ? -30 : 60, y: IF_M ? 30 : 80, scale: 0.8, opacity: 0, display: 'none', duration: 0.8, delay: 0.1 },
      cloud2: { x: IF_M ? 30 : -50, y: 10, scale: 1, opacity: 0, display: 'none', duration: 0.8 },
      cloud3: { x: IF_M ? 30 : -60, y: IF_M ? 30 : 80, scale: 0.8, opacity: 0, duration: 0.8 },
      cloud4: { x: 50, y: 10, scale: 1, opacity: 0, display: 'none', duration: 0.8 },
      object1: { x: IF_M ? 60 : 120, y: 0, scale: 0.6, opacity: 0, display: 'none', duration: 0.8 },
      object2: { x: IF_M ? -60 : -120, y: 0, scale: 0.6, opacity: 0, display: 'none', duration: 0.8 },
      wave1: { x: 0, y: -40, scale: 1, opacity: 0, display: 'none', duration: 0.8 },
      wave2: { x: 0, y: -40, scale: 1, opacity: 0, display: 'none', duration: 0.8 },
      text: { x: 0, y: 50, scale: 0.4, opacity: 0, display: 'none', duration: 0.8 },
    }
  }

  get _WideHideOpt() {}

  get _ShowOpt() {
    return {
      cloud1: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.8, delay: 0.4 },
      cloud2: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.96 },
      cloud3: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.96 },
      cloud4: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.96 },
      object1: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.18 },
      object2: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.18 },
      wave: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.18 },
      wave1: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.18 },
      wave2: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.18 },
      text: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.8 },
    }
  }

  constructor($hero) {
    this.$elms = {}
    this.$hero = $hero
    this.$global = document.querySelectorAll(`[data-elm]`)
    this.$contact = this.$hero.querySelectorAll(`[data-contact]`)
    this.$common = [...this.$contact, ...this.$global]
    Array.from(this.$common, (elm) => {
      const name = elm.dataset.contact || elm.dataset.elm
      if (!(name in this.$elms)) this.$elms[name] = []
      this.$elms[name].push(elm)
    })
  }

  // 初期位置の設定
  async setNarrowHide() {
    const { $elms, _NarrowHideOpt } = this
    for (const name in _NarrowHideOpt) {
      gsap.set($elms[name], _NarrowHideOpt[name])
    }
  }

  async setWideHide() {}

  async setShow() {
    const { $elms, _ShowOpt } = this
    for (const name in _ShowOpt) {
      gsap.set($elms[name], _ShowOpt[name])
    }
    $elms.ground[0].style = ''
  }

  // アニメーションの設定
  async moveNarrowHide() {
    const { $hero, $elms, _NarrowHideOpt } = this
    $hero.classList.remove('is-kv-contact')
    const tl = gsap.timeline({ defaults: { ease: 'power2.out' } })
    for (const name in _NarrowHideOpt) {
      tl.to($elms[name], _NarrowHideOpt[name], '<')
    }
    return tl
  }

  async moveShow() {
    const { $hero, $elms, _ShowOpt } = this
    $hero.classList.add('is-kv-contact')
    return gsap
      .timeline({ defaults: { ease: 'power2.out' } })
      .to($elms.cloud1, _ShowOpt.cloud1, '<')
      .to($elms.cloud2, _ShowOpt.cloud2, '<')
      .to($elms.cloud3, _ShowOpt.cloud3, '<')
      .to($elms.cloud4, _ShowOpt.cloud4, '<')
      .fromTo($elms.object1, { opacity: 1 }, _ShowOpt.object1, '<')
      .fromTo($elms.object2, { opacity: 1 }, _ShowOpt.object2, '<')
      .to($elms.wave1, _ShowOpt.wave1, '<')
      .to($elms.wave2, _ShowOpt.wave2, '<')
      .to($elms.text, _ShowOpt.text, '<')
  }

  async move() {
    this.contactArr = {}
    this.contactRect = {}
    this.$fade = [...document.querySelectorAll(`[data-page='fade']`)]
    const $kv = document.querySelector(`[data-contact='kv']`)
    const $contact = [...$kv.querySelectorAll(`[data-contact]`)]

    await this._imgLoad()

    await new Promise((resolve) => setTimeout(resolve, 50))

    $contact.forEach((elm) => {
      const rect = elm.getBoundingClientRect()
      this.contactArr[elm.dataset.contact] = {
        elm: elm,
        rect: rect,
      }
    })

    this.$common.forEach((elm) => {
      this.contactRect[elm.dataset.contact || elm.dataset.elm] = elm.getBoundingClientRect()
    })

    const baseDuration = 0.82
    const IF_M = innerWidth <= 768
    const _MoveOpt = {
      cloud1: [this.$elms.cloud1, { opacity: 1 }, { opacity: 0, duration: baseDuration }, '<'],
      cloud2: [this.$elms.cloud2, { scale: 1 }, { x: IF_M ? 0 : this._calcX('cloud2'), y: IF_M ? 0 : this._calcY('cloud2'), width: this._calcW('cloud2'), opacity: IF_M ? 0 : 1, duration: IF_M ? 0.28 : baseDuration }, '<'],
      cloud3: [this.$elms.cloud3, { opacity: 1 }, { opacity: 0, duration: baseDuration }, '<'],
      cloud4: [this.$elms.cloud4, { opacity: 1 }, { opacity: 0, duration: baseDuration }, '<'],
      object1: [this.$elms.object1, { opacity: 1 }, { x: this._calcX('object1'), y: this._calcY('object1'), width: this._calcW('object1'), opacity: 1, duration: baseDuration }, '<'],
      object2: [this.$elms.object2, { scale: 1 }, { x: this._calcX('object2'), y: this._calcY('object2'), width: this._calcW('object2'), duration: baseDuration }, '<'],
      wave: [this.$elms.wave, { y: 0 }, { y: this._calcYG('wave'), duration: baseDuration }, '<'],
      text: [this.$elms.text, { opacity: 1 }, { opacity: 0, display: 'none', duration: 0.28 }, '<'],
      river: [this.$elms.river, { y: 0 }, { y: this._calcYG('river'), duration: baseDuration }, '<'],
      ground: [this.$elms.ground, { y: 0 }, { y: this._calcYG('river'), duration: baseDuration }, '<'],
      duck: [this.$elms.duck, { opacity: 1 }, { opacity: 0, duration: 0.24 }, '<'],
      sun: [this.$elms.sun, { opacity: 1 }, { opacity: 0, duration: 0.28 }, '<'],
      hero: [this.$hero, { opacity: 1 }, { opacity: 0, duration: 0.6 }],
      duckB: [this.contactArr.duck.elm, { y: -20, display: 'none' }, { y: 0, display: 'block', duration: 0.28, delay: 0.3 }, '<'],
      fade: [this.$fade, { scale: 1.1, opacity: 0, visibility: 'hidden' }, { scale: 1, opacity: 1, visibility: 'visible', duration: 0.4, delay: 0.3, stagger: 0.04 }, '<'],
    }

    const tl = gsap.timeline({ defaults: { ease: 'power2.out' }, onComplete: () => this._moveComplete() })
    for (const name in _MoveOpt) {
      tl.fromTo(..._MoveOpt[name])
    }
    return tl
  }

  _calcX(name) {
    return this.contactArr[name].rect.x - this.contactRect[name].x
  }

  _calcY(name) {
    return this.contactArr[name].rect.y - this.contactRect[name].y + this.contactArr[name].rect.height - this.contactRect[name].height
  }

  _calcYG(name) {
    return this.contactArr[name].rect.y - this.contactRect[name].y
  }

  _calcW(name) {
    return this.contactArr[name].rect.width
  }

  _imgLoad() {
    return new Promise((resolve, reject) => {
      const image = document.querySelector(`[data-page='fade'] img`)
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = (e) => reject(e)
      img.src = image.src
    })
  }

  _moveComplete() {
    const { $elms } = this
    if (location.pathname !== '/contact/') return true
    for (const key in $elms) {
      $elms[key].forEach((elm) => {
        if (key === 'hero') {
          this.$hero.style.opacity = ''
          gsap.set(elm, { display: 'none' })
        } else if (key === 'cursorInner') {
          gsap.set(elm, { scale: 0.6 })
        } else {
          elm.style = ''
          gsap.set(elm, { x: 0, y: 0, scale: 1 })
        }
      })
    }
  }
}
