export default class Menu {
  constructor() {
    this.$menuBtn = document.querySelector(`[data-menu='button']`)
    this.$menuLink = [...document.querySelector(`[data-menu='link']`).children]
    this.$copyright = document.querySelector(`[data-menu='copyright']`)
    this.$container = document.querySelector(`[data-menu='container']`)
    this.bodyClass = document.body.classList
    this.$menuLink.forEach((elm) => elm.querySelector('a').addEventListener('click', this._close.bind(this)))
    this.$menuBtn.addEventListener('click', this._toggle.bind(this))
    window.addEventListener('resize', this._resize.bind(this))
    this._resize()
  }

  _toggle() {
    if (!this.bodyClass.contains('is-menu-opened')) {
      gsap.fromTo(this.$menuLink, { scale: 1.15, visibility: 'hidden' }, { scale: 1, visibility: 'visible', delay: 0.5, stagger: 0.04 })
      gsap.fromTo(this.$copyright, { opacity: 0 }, { opacity: 1, delay: 0.8 })
      window.menuOpened = true
      this.bodyClass.add('is-menu-opened')
    } else {
      window.menuOpened = false
      this.bodyClass.remove('is-menu-opened')
    }
  }

  _close(event) {
    if (event.currentTarget.pathname === '/') {
      sessionStorage.setItem('top', true)
    }
    this.bodyClass.remove('is-menu-opened')
    window.menuTransition = true
    window.menuOpened = false
  }

  _resize() {
    this.$container.style.height = `${innerHeight}px`
  }
}
