export default class Accordion {
  constructor($elm) {
    this.$elm = $elm
    this.toggle = this.toggle.bind(this)
    this.$buttons = [...$elm.querySelectorAll(`[data-accordion='button']`)]
    this.$container = $elm.querySelector(`[data-accordion='container']`)
    this.$container.style.display = 'block'
    this.$container.style.height = 'auto'
    this.containerHeight = this.$container.offsetHeight
    this.$container.style.display = 'none'
    this.$container.style.height = 0

    this.$buttons.forEach(($elm) => $elm.addEventListener('click', this.toggle))
  }

  toggle() {
    const { $elm, $container, containerHeight } = this
    const elmClass = $elm.classList
    const action = {
      show: [
        {
          height: 0,
          opacity: 0,
          display: 'block',
        },
        {
          height: containerHeight,
          opacity: 1,
          ease: 'power1.in',
          duration: 0.6,
          onStart: () => elmClass.add('is-opened'),
        },
      ],
      hide: [
        {
          height: containerHeight,
          opacity: 1,
        },
        {
          height: 0,
          opacity: 0,
          display: 'none',
          ease: 'power1.out',
          duration: 0.6,
          onComplete: () => elmClass.remove('is-opened'),
        },
      ],
    }

    gsap.fromTo($container, ...action[elmClass.contains('is-opened') ? 'hide' : 'show'])
  }
}
