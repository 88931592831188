export default class AboutAnime {
  get _NarrowHideOpt() {
    const IF_M = innerWidth <= 768
    const moveX = innerWidth / 2
    return {
      cloud1: { x: 0, y: 0, scale: 1, opacity: IF_M ? 0 : 1, display: IF_M ? 'none' : 'block', duration: 0.8, delay: 0.1 },
      cloud2: { x: -50, y: 10, scale: 1, opacity: 0, display: 'none', duration: 0.8 },
      cloud3: { x: IF_M ? moveX / 2 : 400, y: IF_M ? 50 : 110, scale: 0.6, opacity: 0, display: 'none', duration: 0.8 },
      build1: { x: 290, y: 0, scale: 0.6, opacity: 0, display: 'none', duration: 0.8 },
      build2: { x: -320, y: 0, scale: 0.6, opacity: 0, display: 'none', duration: 0.8 },
      build3: { x: IF_M ? 50 : 150, y: 0, scale: 0.6, opacity: 0, display: 'none', duration: 0.8 },
      build4: { x: IF_M ? -50 : -130, y: 0, scale: 0.6, opacity: 0, display: 'none', duration: 0.8 },
      object1: { x: IF_M ? -60 : -100, y: -30, scale: 0.6, opacity: 0, display: 'none', duration: 0.8 },
      text: { x: 0, y: 50, scale: 0.4, opacity: 0, display: 'none', duration: 0.8 },
    }
  }

  get _WideHideOpt() {
    const moveX = innerWidth / 2
    const moveY = innerHeight / 5
    return {
      cloud1: { x: -60, y: -60, scale: 1.2, opacity: 0, display: 'none', duration: 0.86 },
      cloud2: { x: this._rOut('cloud2', 1.1), y: -30, scale: 1.1, opacity: 1, display: 'none', duration: 0.86 },
      cloud3: { x: this._lOut('cloud3', 1.1), y: -30, scale: 1.1, opacity: 1, display: 'none', duration: 0.86 },
      build1: { x: -moveX, y: 0, scale: 1.2, opacity: 1, display: 'none', duration: 1.4 },
      build2: { x: moveX, y: 0, scale: 1.2, opacity: 1, display: 'none', duration: 1.4 },
      build3: { x: -moveX, y: 0, scale: 1.1, opacity: 1, display: 'none', duration: 1.4 },
      build4: { x: moveX, y: 0, scale: 1.1, opacity: 1, display: 'none', duration: 1.4 },
      object1: { x: 160, y: 160, scale: 1, opacity: 1, display: 'none', duration: 0.8 },
      text: { x: 0, y: -moveY, scale: 1, opacity: 0, display: 'none', duration: 1 },
    }
  }

  get _ShowOpt() {
    const IF_M = innerWidth <= 768
    return {
      cloud1: { x: 0, y: -10, scale: 1.1, opacity: 1, display: 'block', overwrite: true, duration: 0.8, delay: 0.4 },
      cloud2: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.96 },
      cloud3: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.18 },
      build1: { x: 0, y: 0, scale: 1, opacity: 1, display: IF_M ? 'none' : 'block', overwrite: true, duration: 1.18 },
      build2: { x: 0, y: 0, scale: 1, opacity: 1, display: IF_M ? 'none' : 'block', overwrite: true, duration: 1.18 },
      build3: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.18 },
      build4: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.18 },
      object1: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 1.18 },
      text: { x: 0, y: 0, scale: 1, opacity: 1, display: 'block', overwrite: true, duration: 0.8 },
    }
  }

  constructor($hero) {
    this.$elms = {}
    this.$hero = $hero
    this.$global = document.querySelectorAll(`[data-elm]`)
    this.$about = this.$hero.querySelectorAll(`[data-about]`)
    this.$common = [...this.$about, ...this.$global]
    Array.from(this.$common, (elm) => {
      const name = elm.dataset.about || elm.dataset.elm
      if (!(name in this.$elms)) this.$elms[name] = []
      this.$elms[name].push(elm)
    })
  }

  // 初期位置の設定
  async setNarrowHide() {
    const { $elms, _NarrowHideOpt } = this
    for (const name in _NarrowHideOpt) {
      gsap.set($elms[name], _NarrowHideOpt[name])
    }
  }

  async setWideHide() {
    const { $elms, _WideHideOpt } = this
    for (const name in _WideHideOpt) {
      gsap.set($elms[name], _WideHideOpt[name])
    }
  }

  async setShow() {
    const { $elms, _ShowOpt } = this
    for (const name in _ShowOpt) {
      gsap.set($elms[name], _ShowOpt[name])
    }
    $elms.ground[0].style = ''
  }

  // アニメーションの設定
  async moveNarrowHide() {
    const { $elms, _NarrowHideOpt } = this
    const tl = gsap.timeline({ defaults: { ease: 'power2.out' } })
    for (const name in _NarrowHideOpt) {
      tl.to($elms[name], _NarrowHideOpt[name], '<')
    }
    return tl
  }

  async moveWideHide() {
    const { $elms, _WideHideOpt } = this
    const tl = gsap.timeline({ defaults: { ease: 'power2.out' } })
    for (const name in _WideHideOpt) {
      tl.to($elms[name], _WideHideOpt[name], '<')
    }
    return tl
  }

  async moveShow() {
    const { $elms, _ShowOpt } = this
    return gsap
      .timeline({ defaults: { ease: 'power2.out' } })
      .fromTo($elms.cloud1, { opacity: 1 }, _ShowOpt.cloud1)
      .fromTo($elms.cloud2, { opacity: 1 }, _ShowOpt.cloud2, '<')
      .fromTo($elms.cloud3, { opacity: 1 }, _ShowOpt.cloud3, '<')
      .fromTo($elms.build1, { opacity: 1 }, _ShowOpt.build1, '<')
      .fromTo($elms.build2, { opacity: 1 }, _ShowOpt.build2, '<')
      .fromTo($elms.build3, { opacity: 1 }, _ShowOpt.build3, '<')
      .fromTo($elms.build4, { opacity: 1 }, _ShowOpt.build4, '<')
      .fromTo($elms.object1, { opacity: 1 }, _ShowOpt.object1, '<')
      .to($elms.text, _ShowOpt.text, '<')
  }

  _lOut(name, scale = 1) {
    const style = window.getComputedStyle(this.$elms[name][0])
    const offset = parseInt(style.getPropertyValue('margin-left'))
    const width = parseInt(style.getPropertyValue('width'))
    return -innerWidth / 2 - offset - width * scale
  }

  _rOut(name, scale = 1) {
    const style = window.getComputedStyle(this.$elms[name][0])
    const offset = parseInt(style.getPropertyValue('margin-left'))
    const width = parseInt(style.getPropertyValue('width'))
    return innerWidth / 2 - offset + (width * (scale - 1)) / 2
  }

  async move() {
    this.aboutArr = {}
    this.aboutRect = {}
    this.$fade = [...document.querySelectorAll(`[data-page='fade']`)]
    const $kv = document.querySelector(`[data-about='kv']`)
    const $about = [...$kv.querySelectorAll(`[data-about]`)]

    await this._imgLoad()

    await new Promise((resolve) => setTimeout(resolve, 50))

    $about.forEach((elm) => {
      const rect = elm.getBoundingClientRect()
      this.aboutArr[elm.dataset.about] = {
        elm: elm,
        rect: rect,
      }
    })

    this.$common.forEach((elm) => {
      this.aboutRect[elm.dataset.about || elm.dataset.elm] = elm.getBoundingClientRect()
    })

    const baseDuration = 0.82
    const IF_M = innerWidth <= 768
    const _MoveOpt = {
      cloud1: [this.$elms.cloud1, { scale: 1.1, opacity: 1 }, { scale: 1.3, opacity: 0, duration: 0.28 }],
      cloud2: [this.$elms.cloud2, { scale: 1 }, { x: IF_M ? 0 : this._calcX('cloud2'), y: IF_M ? 0 : this._calcY('cloud2'), width: this._calcW('cloud2'), opacity: IF_M ? 0 : 1, duration: IF_M ? 0.28 : baseDuration }, '<'],
      cloud3: [this.$elms.cloud3, { scale: 1 }, { x: IF_M ? 0 : this._calcX('cloud3'), y: IF_M ? 0 : this._calcY('cloud3'), width: this._calcW('cloud3'), opacity: IF_M ? 0 : 1, duration: IF_M ? 0.28 : baseDuration }, '<'],
      build1: [this.$elms.build1, { scale: 1 }, { x: this._calcX('build1'), y: this._calcY('build1'), width: this._calcW('build1'), duration: baseDuration }, '<'],
      build2: [this.$elms.build2, { scale: 1 }, { x: this._calcX('build2'), y: this._calcY('build2'), width: this._calcW('build2'), duration: baseDuration }, '<'],
      build3: [this.$elms.build3, { scale: 1 }, { x: this._calcX('build3'), y: this._calcY('build3'), width: this._calcW('build3'), duration: baseDuration }, '<'],
      build4: [this.$elms.build4, { scale: 1 }, { x: this._calcX('build4'), y: this._calcY('build4'), width: this._calcW('build4'), duration: baseDuration }, '<'],
      object1: [this.$elms.object1, { scale: 1 }, { x: this._calcX('object1'), y: this._calcY('object1'), width: this._calcW('object1'), duration: baseDuration }, '<'],
      text: [this.$elms.text, { opacity: 1 }, { opacity: 0, display: 'none', duration: 0.28 }, '<'],
      river: [this.$elms.river, { y: 0 }, { y: this._calcYG('river'), duration: baseDuration }, '<'],
      ground: [this.$elms.ground, { y: 0 }, { y: this._calcYG('river'), duration: baseDuration }, '<'],
      duck: [this.$elms.duck, { opacity: 1 }, { opacity: 0, duration: 0.24 }, '<'],
      sun: [this.$elms.sun, { opacity: 1 }, { opacity: 0, duration: 0.28 }, '<'],
      hero: [this.$hero, { opacity: 1 }, { opacity: 0, duration: 0.6 }],
      cloud1B: [this.aboutArr.cloud1.elm, { opacity: 0 }, { opacity: 1, duration: 0.8 }, '< 0.1'],
      duckB: [this.aboutArr.duck.elm, { y: -20, display: 'none' }, { y: 0, display: 'block', duration: 0.28, delay: 0.3 }, '<'],
      fade: [this.$fade, { scale: 1.1, opacity: 0, visibility: 'hidden' }, { scale: 1, opacity: 1, visibility: 'visible', duration: 0.4, delay: 0.3, stagger: 0.04 }, '<'],
    }

    const tl = gsap.timeline({ defaults: { ease: 'power2.out' }, onComplete: () => this._moveComplete() })
    for (const name in _MoveOpt) {
      tl.fromTo(..._MoveOpt[name])
    }
    return tl
  }

  _calcX(name) {
    return this.aboutArr[name].rect.x - this.aboutRect[name].x
  }

  _calcY(name) {
    return this.aboutArr[name].rect.y - this.aboutRect[name].y + this.aboutArr[name].rect.height - this.aboutRect[name].height
  }

  _calcYG(name) {
    return this.aboutArr[name].rect.y - this.aboutRect[name].y
  }

  _calcW(name) {
    return this.aboutArr[name].rect.width
  }

  _imgLoad() {
    return new Promise((resolve, reject) => {
      const image = document.querySelector(`[data-page='fade'] img`)
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = (e) => reject(e)
      img.src = image.src
    })
  }

  _moveComplete() {
    const { $elms } = this
    if (location.pathname !== '/about/') return true
    for (const key in $elms) {
      $elms[key].forEach((elm) => {
        if (key === 'hero') {
          this.$hero.style.opacity = ''
          gsap.set(elm, { display: 'none' })
        } else if (key === 'cursorInner') {
          gsap.set(elm, { scale: 0.6 })
        } else {
          elm.style = ''
          gsap.set(elm, { x: 0, y: 0, scale: 1 })
        }
      })
    }
  }
}
