import Validate from 'ui/validate'

export default class Contact {
  constructor() {
    this.$hero = document.querySelector(`[data-elm='hero']`)
  }

  init(data) {
    const { $hero } = this
    if (!window.topMove) {
      $hero.style.display = 'none'
    }

    new Validate()
    console.log('Contact index')
  }

  break() {}
}
