import KeyVisualAnime from './keyvisualAnime'
import AboutAnime from './aboutAnime'
import WorksAnime from './worksAnime'
import RecruitAnime from './recruitAnime'
import ContactAnime from './contactAnime'
import CommonAnime from './commonAnime'
import Cursor from './cursor'
import Loading from './loading'

export default class Top {
  get _duckOpt() {
    return [this.$elms.duck, { y: 0 }, { y: 5, yoyo: true, duration: 0.2, repeat: -1, ease: 'linear' }]
  }

  get _titleOpt() {
    return [
      document.querySelectorAll(`[data-title]`),
      {
        keyframes: [
          { scale: 1.07, duration: 0.7 },
          { scale: 0.98, duration: 0.22 },
          { scale: 1, duration: 0.26 },
        ],
        ease: 'power2.out',
      },
    ]
  }

  get _MoveOpt() {
    return {
      top: {
        i: 0,
        s: [
          { p: 'about', m: 'setNarrowHide' },
          { p: 'works', m: 'setNarrowHide' },
          { p: 'recruit', m: 'setNarrowHide' },
          { p: 'contact', m: 'setNarrowHide' },
          { p: 'keyVisual', m: 'setShow' },
        ],
      },
      about: {
        i: 1,
        s: [
          { p: 'keyVisual', m: 'setWideHide' },
          { p: 'works', m: 'setNarrowHide' },
          { p: 'recruit', m: 'setNarrowHide' },
          { p: 'contact', m: 'setNarrowHide' },
          { p: 'about', m: 'setShow' },
        ],
      },
      works: {
        i: 2,
        s: [
          { p: 'keyVisual', m: 'setWideHide' },
          { p: 'about', m: 'setWideHide' },
          { p: 'recruit', m: 'setNarrowHide' },
          { p: 'contact', m: 'setNarrowHide' },
          { p: 'works', m: 'setShow' },
        ],
      },
      recruit: {
        i: 3,
        s: [
          { p: 'keyVisual', m: 'setWideHide' },
          { p: 'about', m: 'setWideHide' },
          { p: 'works', m: 'setWideHide' },
          { p: 'contact', m: 'setNarrowHide' },
          { p: 'recruit', m: 'setShow' },
        ],
      },
      contact: {
        i: 4,
        s: [
          { p: 'keyVisual', m: 'setWideHide' },
          { p: 'about', m: 'setWideHide' },
          { p: 'works', m: 'setWideHide' },
          { p: 'recruit', m: 'setWideHide' },
          { p: 'contact', m: 'setShow' },
        ],
      },
    }
  }

  constructor() {
    this.$elms = {}
    Array.from([...document.querySelectorAll(`[data-elm]`)], (elm) => {
      const name = elm.dataset.elm
      if (!(name in this.$elms)) this.$elms[name] = elm
    })
    this.IF_MOBILE = innerWidth <= 768
    this.count = 0

    this.loading = new Loading()
    this.common = new CommonAnime(this.$elms.hero)
    this.cursor = new Cursor(this.$elms.hero)
    this.keyVisual = new KeyVisualAnime(this.$elms.hero)
    this.about = new AboutAnime(this.$elms.hero)
    this.works = new WorksAnime(this.$elms.hero)
    this.recruit = new RecruitAnime(this.$elms.hero)
    this.contact = new ContactAnime(this.$elms.hero)

    this.duck = gsap.timeline().fromTo(...this._duckOpt)
    this.$elms.duck.addEventListener('mouseenter', () => this.duck.timeScale(2))
    this.$elms.duck.addEventListener('mouseleave', () => this.duck.timeScale(1))

    window.title = gsap.timeline({ paused: true, repeat: -1, repeatDelay: 4 }).to(...this._titleOpt)

    this._scrollFn = this._scrollFn.bind(this)
    this._riverAnime = this._riverAnime.bind(this)
    this.riverInterval = setInterval(this._riverAnime, 400)

    window.addEventListener('resize', this.resizeAction.bind(this))
  }

  resizeAction() {
    this.common.set(this.count)
  }

  async init(data, once = false) {
    const { current, next } = data
    const { _MoveOpt } = this
    let name = current.namespace || next.namespace

    if (sessionStorage.getItem('top') === 'true') {
      name = 'top'
      window.menuTransition = false
      sessionStorage.setItem('top', false)
    }

    if (current) {
      if ('re' in current.url.query) {
        name = current.url.query.re
      }
    }

    if (this.IF_MOBILE) {
      this.$elms.hero.style.height = `calc(var(--vh, 1vh) * 100)`
    }
    gsap.set(this.$elms.hero, { display: 'block', opacity: 1, overwrite: true })
    this.count = _MoveOpt[name].i
    this.common.set(this.count)
    _MoveOpt[name].s.forEach((section) => this[section.p][section.m]())

    this._dots()
    this._zIndex()

    if (once) {
      this.keyVisual.setWideHide()
      await this.loading.run()
      this.keyVisual.loadShow()
    } else {
      this.loading.hide()
      this.cursor.cursorSelect(this.count)
    }
    this._bind()
  }

  break() {}

  irregular(next, current) {
    if (current !== 'top') return
    if (this.count === this._MoveOpt[next].i) {
      window.topMove = true
    }
  }

  _bind() {
    window.addEventListener('wheel', this._scrollFn, { passive: false })
    window.addEventListener('swiped', this._scrollFn, { passive: false })
  }

  _unbind() {
    window.removeEventListener('wheel', this._scrollFn)
    window.removeEventListener('swiped', this._scrollFn)
  }

  async move(data) {
    this._unbind()
    this.cursor.keyAreaLeave()
    if (window.menuTransition) {
      this.$elms.hero.style.display = 'none'
    } else {
      await this[data.next.namespace].move()
    }
  }

  async _scrollFn(event) {
    const { deltaY, detail } = event
    event.preventDefault()

    let prevCount = this.count
    if (window.menuOpened) return true
    if ((detail.dir === 'up' || deltaY > 0) && prevCount < 4) ++prevCount
    if ((detail.dir === 'down' || deltaY < 0) && prevCount > 0) --prevCount
    if (this.count === prevCount) return true
    this.direction = prevCount - this.count > 0 ? 'down' : 'up'

    this.count = prevCount
    this._unbind()
    this._dots()
    clearTimeout(this.titleQ)
    window.title.pause()
    this.cursor.cursorSelect(this.count)
    await this._scrollAction()
    this.titleQ = setTimeout(() => window.title.restart(), 2000)
    console.log('Animation END', this.count)
    if (location.pathname === '/') {
      this._bind()
    }
  }

  async _scrollAction() {
    const actionArray = {
      0: [
        { p: 'keyVisual', m: 'moveShow' },
        { p: 'about', m: 'moveNarrowHide' },
      ],
      1: [
        { p: 'keyVisual', m: 'moveWideHide' },
        { p: 'about', m: 'moveShow' },
        { p: 'works', m: 'moveNarrowHide' },
      ],
      2: [
        { p: 'about', m: 'moveWideHide' },
        { p: 'works', m: 'moveShow' },
        { p: 'recruit', m: 'moveNarrowHide' },
      ],
      3: [
        { p: 'works', m: 'moveWideHide' },
        { p: 'recruit', m: 'moveShow' },
        { p: 'contact', m: 'moveNarrowHide' },
      ],
      4: [
        { p: 'recruit', m: 'moveWideHide' },
        { p: 'contact', m: 'moveShow' },
      ],
    }

    if (!(this.count in actionArray)) return true

    this._zIndex()
    await Promise.all(
      actionArray[this.count].map(async (elm) => {
        return await this[elm.p][elm.m]()
      }),
      await this.common.move(this.count, this.direction)
    )
  }

  _riverAnime() {
    this.$elms.river1.classList.toggle('is-hidden')
    this.$elms.river2.classList.toggle('is-hidden')
    Array.from([...document.querySelectorAll(`[data-contact^='wave']`)], (elm) => {
      elm.classList.toggle('is-hidden')
    })
  }

  _dots() {
    Array.from(this.$elms.dots.children, (elm, index) => {
      const dot = elm.querySelector('span').classList
      dot[index === this.count ? 'add' : 'remove']('is-current')
    })
  }

  _zIndex() {
    const { $elms, count } = this

    $elms.kv.style = ''
    $elms.company.style = ''
    $elms.works.style = ''
    $elms.recruit.style = ''
    $elms.contact.style = ''

    switch (count) {
      case 0:
        $elms.kv.style.zIndex = 7
        break
      case 1:
        $elms.company.style.zIndex = 7
        break
      case 2:
        $elms.works.style.zIndex = 7
        break
      case 3:
        $elms.recruit.style.zIndex = 7
        break
      case 4:
        $elms.contact.style.zIndex = 7
        break
    }
  }
}
