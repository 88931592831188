export default class Slider {
  get _Opt() {
    return {
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      pagination: {
        el: this.$dots,
        bulletElement: 'li',
        bulletActiveClass: 'is-current',
      },
      breakpoints: {
        768: {
          slidesPerView: 'auto',
        },
      },
    }
  }

  constructor() {}

  init() {
    this.$slider = [...document.querySelectorAll(`[data-slider='main']`)]
    if (!this.$slider) return

    this.$slider.forEach(($elm) => {
      this.$dots = $elm.querySelector(`[data-slider='dots']`)
      new window.Swiper($elm, this._Opt)
    })
  }
}
