import Slider from './slider'
import Cursor from './cursor'
import Entry from './entry'

export default class Works {
  constructor() {
    this.slider = new Slider()
    this.cursor = new Cursor()
    this.entry = new Entry()
    this.$hero = document.querySelector(`[data-elm='hero']`)
  }

  async init(data) {
    const { $hero } = this
    if (!window.topMove) {
      $hero.style.display = 'none'
    }

    this._choices()
    await this.entry.init(data)
    this.slider.init()
    this.cursor.init()
  }

  break() {
    this.cursor.break()
  }

  async transition(opacity) {
    await this.entry.transition(opacity)
  }

  _choices() {
    const $choices = document.querySelector(`[data-choices]`)
    if (!$choices) return
    const $item = [...$choices.children]
    let tWidth = 0

    $item.forEach((elm) => {
      const style = window.getComputedStyle(elm)
      const marginL = parseInt(style.getPropertyValue('margin-left'))
      const marginLeft = marginL < 0 ? 0 : marginL
      const marginR = parseInt(style.getPropertyValue('margin-right'))
      tWidth += elm.clientWidth + marginR + marginLeft
    })
    $choices.style.width = `${Math.ceil(tWidth + 20)}px`
  }
}
