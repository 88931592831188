export default class CursorStalker {
  constructor() {
    this.$cursor = document.querySelectorAll(`[data-cursor]`)
    this.$cursorInner = document.querySelectorAll(`[data-cursor-inner]`)

    this.pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    this.mouse = { x: this.pos.x, y: this.pos.y }
    this.speed = 0.05

    this.cursorSetX = gsap.quickSetter(this.$cursor, 'x', 'px')
    this.cursorSetY = gsap.quickSetter(this.$cursor, 'y', 'px')

    // // マウスカーソル座標を取得する
    window.addEventListener('mousemove', (event) => {
      this.mouse.x = event.pageX
      this.mouse.y = event.pageY
    })

    gsap.ticker.add(() => {
      const dt = 1.0 - Math.pow(1.0 - this.speed, gsap.ticker.deltaRatio())
      this.pos.x += (this.mouse.x - this.pos.x) * dt
      this.pos.y += (this.mouse.y - this.pos.y) * dt
      this.cursorSetX(this.pos.x)
      this.cursorSetY(this.pos.y)
    })

    gsap.set(this.$cursorInner, { scale: 0 })
  }

  leave() {
    this.leavePos = window.pageYOffset
  }

  enter() {
    this.enterPos = window.pageYOffset
    this.mouse.y = this.mouse.y + this.enterPos - this.leavePos
  }
}
