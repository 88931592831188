export default class Loading {
  constructor() {
    this.loadingEnd = new CustomEvent('loadingEnd')
    this.$screenLoading = document.querySelector(`[data-loading]`)
    this.$image = this.$screenLoading.querySelector(`img`)
    this.$cursor = document.querySelector(`[data-cursor='scroll']`)
  }

  async run() {
    gsap.fromTo(this.$image, { opacity: 1 }, { opacity: 0, delay: 1, duration: 0.4 })
    gsap.fromTo(this.$screenLoading, { opacity: 1 }, { opacity: 0, display: 'none', delay: 1.4, duration: 0.4 })
    gsap.fromTo(this.$cursor, { scale: 0.6, opacity: 0 }, { scale: 1, opacity: 1, display: 'block', delay: 1.7, duration: 0.3 })
    await new Promise((resolve) => setTimeout(resolve, 1000))
    setTimeout(() => {
      window.dispatchEvent(this.loadingEnd)
    }, 200)
  }

  hide() {
    this.$screenLoading.style.display = 'none'
  }
}
