export default class Validate {
  constructor() {
    this.form = document.querySelector('[data-validate]')
    if (this.form) {
      this.submit = this.form.querySelector(`[data-validate-elm='submit']`)
      this.required = [...this.form.querySelectorAll(`[data-validate-elm='required']`)]
      this.submit.addEventListener('click', (event) => this.onClickSubmit(event))
    }
  }

  onClickSubmit(event) {
    const { required } = this
    let reqArray = {}
    let errorArray = []

    required.forEach((_elm) => {
      const input = _elm.querySelectorAll('input, select, textarea')
      input.forEach((_input) => {
        if (!(_input.name in reqArray)) {
          reqArray[_input.name] = {
            elm: _elm,
            value: _input.value,
          }
        }
        if (_input.type === 'email') {
          _input.value = this.changeText(_input.value)
          if (!_input.value) {
            reqArray[_input.name]['judg'] = false
            reqArray[_input.name]['message'] = 'メールアドレスが入力されていません。'
          } else if (_input.value.match(/.+@.+\..+/)) {
            reqArray[_input.name]['judg'] = true
          } else {
            reqArray[_input.name]['judg'] = false
            reqArray[_input.name]['message'] = '正しいメールアドレスを入力してください。'
          }
        } else if (_input.type === 'radio') {
          if (_input.checked) {
            reqArray[_input.name]['judg'] = true
          }
        } else if (_input.type === 'checkbox') {
          if (_input.checked) {
            reqArray[_input.name]['judg'] = true
          }
        } else {
          if (_input.value.length) {
            reqArray[_input.name]['judg'] = true
          } else {
            reqArray[_input.name]['judg'] = false
          }
        }
      })
    })

    Object.keys(reqArray).forEach((key) => {
      const value = reqArray[key]
      if (value.judg) {
        value.elm.classList.remove('is-error')
      } else {
        value.elm.classList.add('is-error')
        if (value.message) {
          value.elm.querySelector('.form-error').textContent = value.message
        }
        errorArray.push(value.elm)
      }
    })

    // console.log(reqArray)
    // console.log(errorArray)

    if (errorArray.length !== 0) {
      event.preventDefault()
      this.scrollTo(errorArray[0])
    } else {
      this.form.submit()
    }
  }

  changeText(text) {
    const str = text.replace(/[Ａ-Ｚａ-ｚ０-９－！”＃＄％＆’（）＝＜＞，．？＿［］｛｝＠＾～￥]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248)
    })
    return str
  }

  scrollTo(elm) {
    gsap.to(window, {
      duration: 0.75,
      scrollTo: {
        y: elm,
        offsetY: 50,
        autoKill: false,
      },
      ease: 'power3.out',
    })
  }
}
