import 'swiped-events'
import gsap from 'gsap/dist/gsap.min'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin.min'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.min'
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)

import barba from '@barba/core'
import barbaPrefetch from '@barba/prefetch'
barba.use(barbaPrefetch)

import Swiper, { Pagination } from 'swiper'
Swiper.use([Pagination])

import doT from 'dot'
doT.templateSettings = {
  evaluate: /<%([\s\S]+?(\}?)+)%>/g,
  interpolate: /<%=([\s\S]+?)%>/g,
  encode: /<%!([\s\S]+?)%>/g,
  use: /<%#([\s\S]+?)%>/g,
  useParams: /(^|[^\w$])def(?:\.|\[[\'\"])([\w$\.]+)(?:[\'\"]\])?\s*\:\s*([\w$\.]+|\"[^\"]+\"|\'[^\']+\'|\{[^\}]+\})/g,
  define: /<%##\s*([\w\.$]+)\s*(\:|=)([\s\S]+?)#%>/g,
  defineParams: /^\s*([\w$]+):([\s\S]+)/,
  conditional: /<%\?(\?)?\s*([\s\S]*?)\s*%>/g,
  iterate: /<%~\s*(?:%>|([\s\S]+?)\s*\:\s*([\w$]+)\s*(?:\:\s*([\w$]+))?\s*%>)/g,
  varname: 'it',
  strip: true,
  append: true,
  selfcontained: false,
  doNotSkipEncoded: false,
}

window.gsap = gsap
window.barba = barba
window.Swiper = Swiper
window.doT = doT

import Menu from 'ui/menu'
import CursorStalker from 'ui/cursorStalker'
import Top from 'page/top'
import About from 'page/about'
import Works from 'page/works'
import Recruit from 'page/recruit'
import Contact from 'page/contact'

export default class App {
  constructor() {
    window.backPosition = []

    this.pages = {
      top: new Top(),
      about: new About(),
      works: new Works(),
      recruit: new Recruit(),
      contact: new Contact(),
    }

    this.setHeight = this.setHeight.bind(this)

    const ScreenTransition = document.querySelector(`[data-elm='ScreenTransition']`)

    this.eventDelete = this.eventDelete.bind(this)
    this.cursorStalker = new CursorStalker()

    this.setHeight()

    window.addEventListener('popstate', () => (window.browserBack = true))
    window.addEventListener('resize', this.setHeight)

    barba.init({
      // debug: true,
      transitions: [
        {
          once: async (data) => {
            new Menu()
            this.clicklAction()
            this.pages[data.next.namespace].init(data, true)
          },
          beforeLeave: async ({ current }) => {
            const scrollElem = document.scrollingElement || document.documentElement
            if (!window.browserBack) {
              window.backPosition.push(scrollElem.scrollTop)
            }
            this.cursorStalker.leave()
            this.pages[current.namespace].break()
          },
          afterLeave: async ({ current, next }) => {
            this.pages['top'].irregular(next.namespace, current.namespace)
            if (!window.topMove) {
              if (current.url.path === '/works/' && next.url.path === '/works/') {
                await this.pages['works'].transition(0)
              } else {
                await gsap.fromTo(ScreenTransition, { opacity: 0, display: 'block' }, { duration: 0.4, opacity: 1 })
              }
            }
          },
          beforeEnter: async ({ next }) => {
            this.replaceHeadTags(next)
          },
          enter: async (data) => {
            const { current, next } = data
            const nName = next.namespace
            const bodyClass = document.body.classList
            bodyClass[nName === 'top' ? 'remove' : 'add']('is-lower')
            bodyClass.remove(current.namespace)
            bodyClass.add(nName)
            this.pages[nName].init(data)
          },
          afterEnter: async (data) => {
            const { current, next } = data
            if (window.topMove) {
              this.pages['top'].move(data)
              window.topMove = false
            } else {
              if (!(current.url.path === '/works/' && next.url.path === '/works/')) {
                gsap.fromTo(ScreenTransition, { opacity: 1, display: 'block' }, { duration: 0.6, opacity: 0, delay: 0.2, display: 'none' })
              }
            }
            this.clicklAction()
            this.pagePosition(data)
            this.pageScroll(next.url.hash)
            this.cursorStalker.enter()
          },
        },
        {
          name: 'self',
          beforeLeave: async ({ current }) => {
            const scrollElem = document.scrollingElement || document.documentElement
            if (!window.browserBack) {
              window.backPosition.push(scrollElem.scrollTop)
            }
            this.cursorStalker.leave()
            await gsap.fromTo(ScreenTransition, { opacity: 0, display: 'block' }, { duration: 0.4, opacity: 1 })
            this.pages[current.namespace].break()
          },
          beforeEnter: async ({ next }) => {
            this.replaceHeadTags(next)
          },
          enter: async (data) => {
            const { current, next } = data
            const cName = current.namespace
            const nName = next.namespace
            const bodyClass = document.body.classList
            bodyClass[nName === 'top' ? 'remove' : 'add']('is-lower')
            bodyClass.remove(cName)
            bodyClass.add(nName)
            this.pages[nName].init(data)
          },
          afterEnter: async (data) => {
            const { current, next } = data
            this.clicklAction()
            this.pagePosition(data)
            gsap.fromTo(ScreenTransition, { opacity: 1, display: 'block' }, { duration: 0.6, opacity: 0, delay: 0.2, display: 'none' })
            this.pageScroll(next.url.hash)
            this.cursorStalker.enter()
          },
        },
      ],
    })

    barba.hooks.after((data) => {
      if (typeof gtag === 'function') {
        gtag('config', window.GA_MEASUREMENT_ID, {
          page_path: window.location.pathname,
        })
      }
    })
  }

  replaceHeadTags(target) {
    const head = document.head
    const targetHead = target.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0]
    const newPageHead = document.createElement('head')
    newPageHead.innerHTML = targetHead
    const removeHeadTags = [`meta[name='keywords']`, `meta[name='description']`, `meta[property^='fb']`, `meta[property^='og']`, `meta[name^='twitter']`, `meta[name='robots']`, `meta[itemprop]`, `link[itemprop]`, `link[rel='prev']`, `link[rel='next']`, `link[rel='canonical']`].join(',')
    const headTags = [...head.querySelectorAll(removeHeadTags)]
    headTags.forEach((item) => head.removeChild(item))
    const newHeadTags = [...newPageHead.querySelectorAll(removeHeadTags)]
    newHeadTags.forEach((item) => head.appendChild(item))
  }

  pageScroll(hash) {
    if (!hash) return false
    setTimeout(() => {
      const IF_TOP = hash !== 'top'
      let y = 0
      let d = window.pageYOffset / 4000
      if (IF_TOP) {
        const rect = document.querySelector(`#${hash}`).getBoundingClientRect()
        y = window.pageYOffset + rect.top
        d = Math.abs(rect.top) / 4000
      }

      // console.log(y, d)

      gsap.to(window, {
        duration: d,
        scrollTo: {
          y: y,
          offsetY: 50,
          autoKill: false,
        },
        ease: 'power2.out',
      })
    }, 300)
  }

  pagePosition(data) {
    const { current, next } = data
    const scrollElem = document.scrollingElement || document.documentElement
    if (window.browserBack) {
      setTimeout(() => {
        window.browserBack = false
        scrollElem.scrollTop = window.backPosition[window.backPosition.length - 1]
        window.backPosition.pop()
      }, 100)
    } else {
      if (current.url.path === '/works/' && next.url.path === '/works/') return
      scrollElem.scrollTop = 0
    }
  }

  clicklAction() {
    Array.from([...document.querySelectorAll('a[href]')], ($elm) => {
      $elm.addEventListener('click', this.eventDelete, false)
    })
  }

  eventDelete(event) {
    const $target = event.currentTarget
    const move = $target.dataset.chase

    if ($target.dataset.top === 'top') {
      sessionStorage.setItem('top', true)
    }

    if (location.href === $target.href) {
      if (location.pathname === '/') {
        this.pages['top'].init({ current: '', next: { namespace: 'top' } })
      }
      this.pageScroll($target.hash.replace('#', ''))
      event.preventDefault()
      event.stopPropagation()
      return
    } else if (move === '') {
      window.topMove = true
    }
  }

  setHeight() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}

new App()
